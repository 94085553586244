<template lang="pug">
include ../../../configs/template.pug
div.row.mt-4.align-end
  div(v-if="!$route.params?.documentID").col-sm-12.col-md-6
    +select-validation('body.statement_dkk', 'successProtocolsSQC', 'approvedStatements', '"number"', '["required"]')
  div(v-if="!$route.params?.documentID").col-sm-12.col-md-6
    +date-picker-validation('body.date_meeting', 'dateOfThe', 'dateMeetingObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" min="1900-01-01")
  div.col-sm-12.col-md-6
    +select-validation('headCommission', 'commissionList', 'headCommission', '"user_fio_ukr"', '["required"]')(return-object)
  div.col-sm-12.col-md-6
    +select-validation('secretaryCommission', 'commissionList', 'secretaryCommission', '"user_fio_ukr"', '["required"]')(return-object)
  div.col-12
    +select-validation('membersCommission', 'commissionList', 'membersCommission', '"user_fio_ukr"', '["required", "minLength", "maxLength"]')(
      return-object multiple)
  div(v-if="!$route.params?.documentID").col-12
    +select-validation('body.decision', 'decisionsList', 'solution', 'nameLang', '["required"]')
  FileDropZone(ref="mediaContent").col-12
  div.col-12
    v-btn(@click="saveNewProtocolSQC" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>

import { sailorProtocolSQCForm } from '@/mixins/validationRules'
import { mapState, mapActions } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'
const initBody = () => ({
  statement_dkk: null,
  date_meeting: null,
  decision: null
})
export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: { protocol: { type: Object, default: () => ({}) } },
  data () {
    return {
      body: initBody(),
      membersCommission: null,
      headCommission: null,
      secretaryCommission: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      lang: state => state.main.lang,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      successProtocolsSQC: state => state.sailor.successStatements,
      commissionList: state => state.directory.commission,
      decisionsList: state => state.directory.solutions
    }),
    dateMeetingObject () {
      return this.body.date_meeting ? new Date(this.body.date_meeting) : null
    },
    isAddingForm () { return this.$route.name === 'sqc-protocols' }
  },
  validations () { return sailorProtocolSQCForm(this) },
  mounted () {
    if (this.$route.params.documentID) {
      const { membersCommission, headCommission, secretaryCommission } = this.protocol
      this.membersCommission = this.commissionList.filter(user => membersCommission.find(el => el.signer === user.signer))
      this.headCommission = this.commissionList.find(user => user.signer === headCommission.signer)
      this.secretaryCommission = this.commissionList.find(user => user.signer === secretaryCommission.signer)
    }
    this.getSuccessStatementsSQC({ ...this.$route.params })
  },
  methods: {
    ...mapActions(['getSuccessStatementsSQC', 'setProtocolsSQC', 'updateProtocolsSQC']),

    async saveNewProtocolSQC () {
      if (this.$v.$invalid) return this.$v.$touch()

      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'protocls_SQC_doc'
        }
      }

      let membersCommission = [
        { signer: this.headCommission.id, commissioner_type: 'HD' },
        { signer: this.secretaryCommission.id, commissioner_type: 'SC' },
        ...this.membersCommission.map(value => ({ signer: value.id, commissioner_type: 'CH' }))
      ]

      data.body.commissioner_sign = membersCommission

      this.isLoading = true
      const response = await this[`${this.$route.params?.documentID ? 'update' : 'set'}ProtocolsSQC`](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        !this.$route.params?.documentID && (this.$parent.isViewAddSlot = false)
        this.$v.$reset()
        this.body = initBody()
      }
      this.isLoading = false
    }
  }
}
</script>
